
import { defineComponent, reactive, ref } from "vue";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import RadioButton from "primevue/radiobutton";
import * as Catalog from "../api/helpers/Catalog";
import Spinner from "../components/Spinner.vue";

export default defineComponent({
  name: "TypeRadioButton",
  components: {
    RadioButton,
    Spinner,
  },
  data() {
    return {
      spinnerColor: process.env.VUE_APP_SPINNER_COLOR,
      spinnerTextColor: process.env.VUE_APP_SPINNER_TEXT_COLOR,
    };
  },
  props: {
    isRequired: Boolean,
    isSubmitted: Boolean,
    catalog: String,
    answerKey: String,
  },
  setup(props) {
    var loading = ref(true);
    var catalogData = ref({});
    const getCatalog = async () => {
      if (props.catalog == "profession") {
        await Catalog._find(
          `/profession?system=${process.env.VUE_APP_SYSTEM_ID}`
        ).then((res) => {
          catalogData.value = res.data.data;
          loading.value = false;
        });
      } else if (props.catalog == "income") {
        await Catalog._find(
          `/income?order=id:asc&system=${process.env.VUE_APP_SYSTEM_ID}`
        ).then((res) => {
          catalogData.value = res.data.data;
          loading.value = false;
        });
      }
    };
    const f = reactive({
      name: null,
    });

    var rules;
    rules = props.isRequired
      ? (rules = {
          name: { required },
        })
      : (rules = {
          name: {},
        });

    if (props.isRequired) rules.name.required = required;

    const v$ = useVuelidate(rules, f);
    return { f, v$, catalogData, getCatalog, loading };
  },
  mounted() {
    var step = JSON.parse(sessionStorage.getItem("steps") || "{}");
    if (
      typeof step === "object" &&
      this.answerKey &&
      Object.prototype.hasOwnProperty.call(step, this.answerKey)
    )
      this.v$.name.$model = step[this.answerKey];
    this.getCatalog();
    this.setValues();
  },
  methods: {
    setValues() {
      this.$emit("getValue", {
        value: this.v$.name.$model || undefined,
        valid:
          !(!this.v$.name.$model && this.isRequired) && !this.v$.name.$invalid,
        answerKey: this.answerKey,
      });
    },
  },
});
