import Questions from "@/models/Questions";


export default class Questionnaire {
  id!: number;
  name!: string;
  code?: string;
  questions!: Array<Questions>;
}


