import { Gondor } from "../Gondor";

const ACTIONS = {
  LEAD: "/lead",
};

export function create(body: any) {
  return Gondor.http.post(ACTIONS.LEAD, body);
}
export function update(body: any, leadid: string) {
  return Gondor.http.patch(`${ACTIONS.LEAD}/${leadid}`, body);
}
