
import { defineComponent, reactive } from "vue";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import InputSwitch from "primevue/inputswitch";

export default defineComponent({
  name: "TypeSwitch",
  components: {
    InputSwitch,
  },
  props: {
    label: String,
    isRequired: Boolean,
    isSubmitted: Boolean,
    answerKey: String,
  },
  setup(props) {
    const f = reactive({
      name: false,
    });

    var rules;
    rules = props.isRequired
      ? (rules = {
          name: { required },
        })
      : (rules = {
          name: {},
        });

    if (props.isRequired) rules.name.required = required;

    const v$ = useVuelidate(rules, f);
    return { f, v$ };
  },
  mounted() {
    var step = JSON.parse(sessionStorage.getItem("steps") || "{}");
    if (
      sessionStorage.getItem("steps") &&
      typeof step === "object" &&
      this.answerKey
    )
      this.v$.name.$model = step[this.answerKey] || undefined;
    this.setValues();
  },
  methods: {
    setValues() {
      this.$emit("getValue", {
        value: this.v$.name.$model,
        valid: !(!this.v$.name.$model && this.isRequired),
        answerKey: this.answerKey,
      });
      if(this.answerKey === 'termsAndConditions' ){
        this.$emit("getValue", {
          value: this.v$.name.$model,
          valid: !(!this.v$.name.$model && this.isRequired),
          answerKey: 'privacyNotice',
        });
      }
    },
  },
});
