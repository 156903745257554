export function find() {
  return {
    id: 0,
    name: "Gondolin",
    code: "code",
    questions: [
      {
        id: 9,
        order: 1,
        maxAnswers: 1,
        required: true,
        hasFilter: false,
        name: "Datos de contacto",
        details: `Ingresa tu número celular a ${process.env.VUE_APP_PHONE_LENGTH} dígitos, tu correo electrónico y haz clic en Siguiente`,
        answersKeys: [
          {
            id: 0,
            name: "Teléfono celular",
            key: "phone",
            order: 1,
            type: "string",
            component: "phone",
            required: true,
          }, {
            id: 1,
            name: "Correo electrónico",
            key: "email",
            order: 2,
            type: "string",
            component: "email",
            required: true,
          },
        ],
      },
      {
        id: 10,
        order: 1,
        maxAnswers: 1,
        required: true,
        hasFilter: false,
        name: "Para continuar acepta lo siguiente:",
        details: "",
        answersKeys: [
          {
            id: 2,
            key: "termsAndConditions",
            name: "Acepto los términos y condiciones del servicio",
            order: 0,
            type: "boolean",
            component: "switch",
            required: true,
            catalog: "",
          },{
            id: 3,
            name: "Acepto el aviso de privacidad",
            key: "privacyNotice",
            order: 0,
            type: "boolean",
            component: "switch",
            required: true,
            catalog: "",
          },
        ],
      },
      {
        id: 11,
        order: 2,
        maxAnswers: 1,
        required: true,
        hasFilter: false,
        name: "¿Cuál es tu ingreso mensual?",
        details: "",
        answersKeys: [
          {
            id: 4,
            key: "incomeId",
            order: 0,
            type: "string",
            component: "radiobutton",
            required: true,
            catalog: "income",
          },
        ]
      },
      {
        id: 12,
        order: 2,
        maxAnswers: 1,
        required: true,
        hasFilter: false,
        name: "¿Puedes comprobar tus ingresos?",
        details: "",
        answersKeys: [
          {
            id: 5,
            key: "checkIncome",
            order: 0,
            type: 'boolean',
            component: "yesnobutton",
            required: true,
            catalog: "",
          },
        ],
      },
      {
        id: 13,
        order: 3,
        maxAnswers: 1,
        required: true,
        hasFilter: false,
        name: "¿Cuál es tu estatus laboral actualmente?",
        details: "",
        answersKeys: [
          {
            id: 6,
            key: "professionId",
            order: 0,
            type: "string",
            component: "radiobutton",
            required: true,
            catalog: "profession",
          },
        ],
      },
      {
        id: 14,
        order: 4,
        maxAnswers: 1,
        required: true,
        hasFilter: false,
        name: "¿Tienes una tarjeta bancaria de débito a tu nombre?",
        details: "Responde \"Sí\", en caso de contar con una tarjeta de débito, o \"No\", en caso de no tener una tarjeta de débito a tu nombre. Después, haz clic en Siguiente. <br> *Puede ser de nómina, o de cualquier institución bancaria",
        answersKeys: [
          {
            id: 7,
            key: "debit",
            order: 0,
            type: "boolean",
            component: "yesnobutton",
            required: true
          },
        ],
      },
      {
        id: 15,
        order: 5,
        maxAnswers: 1,
        required: true,
        hasFilter: false,
        name: "Fecha de nacimiento",
        details: "",
        answersKeys: [
          {
            id: 8,
            key: "birthdate",
            order: 0,
            type: "date",
            component: "date",
            required: true,
            catalog: "days",
          },
        ],
      },
      {
        id: 16,
        order: 5,
        maxAnswers: 1,
        required: true,
        hasFilter: false,
        name: "¿Dónde vives?",
        details: "",
        answersKeys: [
          {
            id: 9,
            key: "stateId",
            order: 0,
            type: "string",
            component: "dropdown",
            required: true,
            catalog: "state",
          },
        ],
      },
    ],
  };
}
